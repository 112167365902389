@keyframes flash {
    0% {
        background-color: white;
    }
    50% {
        background-color: #00D1FF;
    }
    100% {
        background-color: white;
    }
}

/* Apply animation to the div */
.flashingDiv {
    border: 1px solid black;
    animation: flash 1s infinite;
}
