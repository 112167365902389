.ghost {
    position: absolute;
    z-index: 10000;
    opacity: 70%;
}
.objectComponent {
    background-color: red;
}
.folderComponent {
    background-color: yellow;
}

.app {
}

.app > ul {
    margin-block-start: 0;
    padding-inline-start: 0 !important;

}

.app ul {
    list-style-type: none;
    padding-inline-start: 20px;

}

.app li {
    list-style-type: none;
}

.treeRoot {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
}



.draggingSource {
    opacity: 0.3;
}

.dropTarget {
    background-color: #EEEEEE;
    transition: background-color 0.5s ease-in;
}

.placeholderContainer {
    position: relative;
}
.main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.scrollWrapper {
    height: calc( 100% - 50px);
    width: 100%;
    overflow: auto;
}

.searchFieldWrapper {
    height: 50px;
}

.searchField {
    width: 100%;
    height: 100%;
    margin-top: 10px;
}
