.main {
    background-color: white;
    height: 100%;
    width: 100%;
    overflow: auto;
}
.fieldDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.buttonDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 130px;
}
.listWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.list {
    text-align: left;
    width: 300px;
}
.validIcon {
    color: green;
    padding-left: 10px;
    margin-bottom: 20px;
}
.invalidIcon {
    color: red;
    padding-left: 10px;
    margin-bottom: 20px;
}
