.root {
    cursor: pointer;
    text-align: center;
    display: flex;
    margin: 10px;
    border: lightgray dashed 1px;
}

.root:hover p, .root:hover svg, .root img {
    opacity: 1;
}

.root p, .root svg {
    opacity: 0.4;
}

.root:hover img {
    opacity: 0.3;

}

.noMouseEvent {
    pointer-vents: none;
}
.iconText {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;

}

.iconText:hover {
    border: none;
}

.hidden {
    display: none;
}
.onDragOver img {
    opacity: 0.3;
}
.onDragOver p, .onDragOver svg {
    opacity: 1;
}

.noMouseEvent:hover {
    margin: -2px -2px -2px -2px;
    border: 2px dotted black;
}
.linearProgressWrapper {
    padding: 5px;
}
.contentText {
    display: block;
    width: 100%;
    text-align: center;
}
