.main {
    height: 95%;
    width: 100%;
}
.splitterComponent {
    height: 100%;
    width: 100%;
}

.bumper {
    width: 100%;
    height: 20px;
}

.lowerControlPane {
    z-index: -1000;
}

.upperControlPane {
    z-index: -1000;
    height: 100%;
    width:100%;
    overflow: scroll;
}

.diagramTitleDiv {
    width: 100%;
    height: 30px;
    border-bottom: lightgrey solid 1px;
    font-size: 20px;
    margin-top: 5px
}

.diagramNameSpan {
    font-weight: bolder;
}

.filterPane {
    width: 100%;
    height: 40px;
}
.diagramDiv {
    /*position: absolute;*/
    width: 100%;
    height: calc(100% - 55px);
    ooverflow: scroll;
}

.gridStackItem {
    background-color: orange;
}
.gridStackItemContent {
    background-color: orange;
}

.dropZoneActive {
    display: block;
    z-index: 1;
    width: 100%;
    height:100%;
    position: absolute;
    background-color: #0d419d;
    opacity: 0.5;
}
.dropZoneInactive {
    display: block;
    opacity: 0.5;
}

