.main {
    padding-top: 50px;
    width: 95%;
    height: 100%;
    background-color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
}

.main p {
    font-size: 1.5em;
    text-align: center;
    padding-top: 20px;
}
