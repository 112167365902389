.main {

}

.row {
    background: #d9d9d9;
    color: #1A1A1A;
    margin: 5px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}
