.mainDiv {
    background-color: white;
    padding-top: 40px;
    color: black;
    width: 100%;
    height: 80%;
}

.uploadContainerDiv {
    width: 600px;
    margin: auto;
}

.userMessage {
    font-weight: bold;
    display: block;
    padding-bottom: 10px;
}
.statusMessage {
    display: block;
    padding-bottom: 10px;
}
