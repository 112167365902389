.mainDiv {

}

.dialogTitle {
}

.actionsDiv {
    display: block;
    right: 0;
    top: 5px;
    position: absolute;
}

.form {

}

.submit {

}
