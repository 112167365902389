.supportLink {
    display: inline-block;
}

.supportLinkIconLink {
    height: 20px;
    width: 20px;
}
.supportLinkIconLink svg {
    height: 20px;
    width: 20px;
    vertical-align: bottom;
}
