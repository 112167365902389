.main {
    overflow: scroll;
    height: 100%;
    width: 100%;
}

.card {
    display: inline-block;
    user-select: none;
}
.card:hover {
    cursor: pointer;
}
.nodeName {
    padding: 5px;
}
.filterPane {
    width: 100%;
    height: 40px;
}
