.mainDiv {
    width: 100%;
    height: 100%;
}
:global .joint-selection {
    background-color: red;
    border: 2px dashed red;
    opacity: .7;
}
:global .selection-box {
    border: 3px solid #FC5185 !important;
    margin-left: -5px !important;
    margin-top: -5px !important;
}
:global .selection-wrapper {
    border: 1px dotted #FC5185 !important;

}
.navigationWrapper {
    height: 0;
    width: 150px;
    background: none;
    margin-left: calc(100% - 150px);
    z-index: 1000;
}

.navigatorDiv {
    border: #0d1117 1px solid;
    position: absolute;
    display: block;

    background-color: white;
    z-index: 9997; /* the support form popup has a z-index of 9999 ad the support button on 9998 */
    overflow: hidden;
    bottom: 20px;
    right: 20px;
}

.navigatorDiv :global .current-view {
    border: #FC5185 1px solid;
}

.paperDiv {
    height: 100%;
    width: 100%;
}
