.iconButton {
    border-radius: 0 ! important;

}

.selectedIconButton {
    border-radius: 0 ! important;
    background-color: lightgrey;
}

.splitterButtonsDiv {
    background-color: #FFFFFF;
    padding: 5px;
    text-align: end;
}

.selectedIconButton {
    background-color: lightgrey;
}
