.main {
    overflow: scroll;
    height: 100%;
    width: 100%;
}


.nodeName {
    padding: 5px;
}
.filterPane {
    width: 100%;
    height: 40px;
}


.labelNewApplication {
    color: green;
    font-weight: bolder;
}
.labelUpdatedApplication {
    color: orange;
    font-weight: bolder;
}
.labelDeletedApplication {
    color: red;
    font-weight: bolder;
}
