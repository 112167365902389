.main {
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.nodeName {
    padding: 5px;
}
.filterPane {
    width: 100%;
    height: 40px;
}

