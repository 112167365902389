.horizontallyPositionedBand {
    vertical-align: top;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 10px;
}
.verticallyPositionedBand{
    vertical-align: top;
    align-items: flex-start;
    display: inline-flex;
    flex-direction: row;
    text-align: center;
    padding: 10px;
}
.bandTitle {
    font-weight: bolder;
    font-size: small;
    display: inline-flex;
    min-height: 50px;
    width: 95px;
    vertical-align: bottom;
    align-items: center;
    text-align: center;
    justify-content: center;
    overflow: hidden;
}
.subCapaListDiv {
    display: block;
    padding-top: 10px;
}
.subCapability {
    border-color: white;
    background-color: white;
    color: black;
    border-radius: 2px;
    display: inline-grid;
    padding: 5px;
    margin: 2px;
    font-size: x-small;
    overflow: hidden;
    width: 75px;
    hheight: 30px;
    text-overflow: ellipsis;
    align-items: center;
    font-weight: bolder;
}

.nonHighlighted {
    border-radius: 2px;
    padding: 2px;
    margin: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.highlighted {
    border: #FC5185 solid 4px !important;
    color: #FC5185;
    border-radius: 2px;
    margin: 1px;
    padding: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.band01 {
    background-color: #f3c0cf;
    color:white;
}
.band02 {
    background-color: sandybrown;
    color:white;
}
.band03 {
    background-color: orange;
    color:white;
}
.band04 {
    background-color: deepskyblue;
    color:white;
}
.band05 {
    background-color: cornflowerblue;
    color:white;
}
.band06 {
    background-color: mediumblue;
    color:white;
}
.band07 {
    background-color: seagreen;
    color:white;
}
.band08 {
    background-color: cadetblue;
    color:white;
}
.band09 {
    background-color: darkseagreen;
    color:white;
}
.band10 {
    background-color: lightseagreen;
    color:white;
}
.subBand {
    background-color: white;
    color:black;
    border: #4a4d6e solid 1px;
    border-radius: 5px;
    padding: 5px;
}
.mapRow {
    display: inline-flex;
    flex-direction: row;
}
.map {
    user-select: none;
    height: 100%;
}
