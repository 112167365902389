.joint-flash-message .fg {
  background-color: #CCF7FF;
  color: black;
  font-weight: bold;
}
.joint-flash-message .btn-close {
  background-color: #CCF7FF;
  color: #0a3069;
  font-weight: bold;
  border: none;

}

.joint-flash-message .btn-close:hover {
  color: white
}

.joint-flash-message .fg [data-type="alert"]{
  background-color: red;
  color: white;
  font-weight: bold;
}
.joint-flash-message .btn-close [data-type="alert"] {
  background-color: red;
  color: darkred;
  font-weight: bold;
  border: none;

}


#root {
  height: 100vh;
  width: 100vw;
}

.canvas {
  width: 100%;
  height: 100%;
}


#treeview {
  height: 100%;
  overflow: scroll;

}

* {
  font-family: "Inter", sans-serif;
}

html {
  width: 100%;
  height: 100%;
  margin: 0;

}
body {
  background-color: #282c34;
  width: 100%;
  height: 100%;
  margin: 0;
}

.app-main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.AppBarLocal {
  background-color: #c0c002 !important;
}

.AppBarStaging {
  background-color: #c58203 !important;
}

.AppBarProd {

}

.AppBarUnknown {
  /*background-color: red !important;
  not giving it any color, as otherwise before the environment info is loaded (takes 1-2 seconds) the Toolbar becomes red :(
  */
}

.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.statusMessageDiv {
  text-align: right;
  right: 0;
  position: absolute;
  padding-right: 20px;
}

.statusMessageSpan {

}

.pleaseLogonSpan {
  margin-top: 100px;
  width: 100%;
  color: white;
  display: block;
}

#root {
  height: 100%;
}

.pagecontent {
  top: 70px;
  position: absolute;
  width: 100%;
  height: calc(100% - 120px); /* allow for a footer with the support button*/

}

.a,.a:hover,.a:visited {
  text-decoration: none;
  color:inherit;
}

.MuiToolbar-root {
  display: contents;
}

/*Only change the color for the AppBar. Changing the color for MuiToolbar-root will impact a lot of MUI components */
.MuiPaper-root.MuiAppBar-root {
  background-color: #3C4048FF;
}


.MuiButtonBase-root.MuiIconButton-root {
  color: white;
}
.MuiPaper-root.MuiDrawer-paper {
  background-color: #3C4048FF;
}

.MuiContainer-root {
  height: 100%;
}


.MuiBox-root {
  height: 100%;
}

.App {
  text-align: center;
  width: 100%;
  height:100%;
  margin-left: auto;
  margin-right: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.App-link {
  color: #FFFFFF; /*#61dafb;*/
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.drawerDiv {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 10px;
  cursor: pointer;
  align-items: center;
}

.drawerImage {
  padding-top: 5px;
}

.drawerLabel {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  flex: 1;
  text-align: right;
  font-weight: bold;
}

.midWindowMessage {
  color: white;
  font-weight: bolder;
}

.logoname {
  font-family: Syne, sans-serif;
  font-weight: 600;
  color: #00D1FF;
}

.MuiButtonBase-root.MuiButton-contained {
  background-color: #00D1FF;
}

.MuiButtonBase-root.MuiButton-contained:hover {
  background-color: #5CE2FF;
}

.MuiButtonBase-root.MuiButton-outlined {
  border-color: #00D1FF;
  color: #00D1FF;
}

.MuiButtonBase-root.MuiButton-outlined:hover {
  border-color: #5CE2FF;
  color: #5CE2FF;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked {
  border-color: #FC5185;
  color: #FC5185;
}


.external_link {
  font-weight: 600;
  font-size: 1rem;
  color: #FFFFFF;
  text-decoration: none;
  padding-left: 2px;
  padding-right: 2px;
  cursor: pointer;
}

.external_link:hover {
  color: #00D1FF;
  text-decoration: underline;
}

.external_link:visited {
  color: #FFFFFF;
  text-decoration: none;
}

.drawerDiv {
  color: #00d1ff;
  user-select: none;
  min-height: 50px;
  font-size: large;
}

.drawerDiv:hover, .drawerLabel:hover {
  background-color: #00d1ff;
  color: white;
  border-radius: 3px;
  user-select: none;
}
.retryLink {
  text-decoration: underline;
}
.retryLink:hover {
  color: #E45B53;
  cursor: pointer;
}

#applicationcost-treemap {
  height: calc(100% - 60px);
}

.logon-infobox {
  position: absolute;
  top: 70px;
  display: block;
  margin-top: 0px;
  min-width: 840px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.muiTableDiv .MuiButtonBase-root.MuiIconButton-root {
  color: #00D1FF;
}


.muiTableDiv .MuiTablePagination-actions .MuiButtonBase-root.MuiIconButton-root.Mui-disabled {
  color: #d1d1d1;
}

#launcher-frame {
  bottom: 0 !important;
  height: 50px !important;
  right: 15px !important;
}

div [data-test="ring-popup form-popup"] {
  z-index: 9999;
}
