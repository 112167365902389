.root {
    align-items: center;
    display: flex;
    height: 32px;
}

.root:hover {
    background-color: #00D1FF;
}
.draggedOver:hover {
    background-color: #555555;
}

.expand {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transform: rotate(0deg);
}

.expand > div {
    align-items: center;
    display: flex;
}

.handle {
    cursor: grab;
    display: flex;
}

.handle > svg {
    pointer-events: none;
}

.label {
    padding-inline-start: 8px;
    width: calc(100% - 64px);
}



.labelText {
    display: inline-block;
    white-space: nowrap;
    ooverflow: hidden;
    ttext-overflow: ellipsis;
    width: calc(100% - 24px);
}

.draggable {
    bbackground-color: green;
}
.draggable:hover {
    cursor: grab;
}
.draggable:active {
    cursor: grabbing;
}
.draggable:active > .handle {
    cursor: grabbing;
}
.undraggable {
    bbackground-color: red;
}
.toggle, .noToggle {
    cursor: auto;
    display: block;
    height: 24px;
}
.noToggle {
    padding-left: 24px;
}
.selected {
    background-color: #FC5185;
}
.softSelected {
    background-color: #FFD166;
}
.itemWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}


