.treeNavigator2 {
    width: 100%;
    height: 100%;
    max-height: 100%;
    /*margin: -5px;*/
    /*background-color: yellow;*/
    display: flex;
    flex-flow: column;
}

.treeNavigator2.fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    right: -5px;
    bottom: 0px;
    z-index: 3000;
    height: calc(100% - 80px)
}

.topSpacer {
    height:50px;
    width:100%;
    /*background-color: #99a011;*/
    flex: 0 1 auto;
}

.navigatorPane {
    width: 100%;
    /*flex: 1 1 auto;
    display: flex;
    flex-flow: column;*/
    height: 100%;

}

.mainBar {
    height: 40px;
    background-color: white;
    width: 100%;

    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.contextualMenuBar {
    height: 30px;
    background-color: white;
    text-align: left;
    padding-left: 5px;
    align-items: center;
    /*
    display: inline-flex;
    flex: 0 1 auto;

     */

}
.actionButton {
    height: 25px;
}
.splitterBar {
    background-color: white;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    height: calc(100% - 120px);
}

.splitterComponent {
    height: 100%;
}


.leftControlPane {
    text-align: left;
    border: none;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    position: relative;
    display: flex;
    flex-direction: column;
}

.treeFilter {
    padding-top: 10px;
    height: 70px;
    width: 100%;
    flex: 0 1 auto;
}

.treeFilterField {
    width: 100%;
    height: 100%;
}

.treeViewPane {
    height: 100%;
    width: 100%;
    background-color: white;
    ooverflow: scroll;
    flex: 1 1 auto;
    ddisplay: inline-table;
    flex-flow: column;
}

.centerControlPane {
    height:100%;
    overflow: hidden;
    width:100%;
}

.rightControlPane {
    /*z-index:500; to remain on top of the drop selection pane*/
    border: none;
    height: 100%;
    width:100%;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
}
.propertiesPaneWrapper {
    height: 100%;
    width: 100%;
    background-color: white;
    flex: 1 1 auto;
    display: inline-table;
    flex-flow: column;
}
.propertiesPane {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.scenarioSelectDiv {
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
    height: 25px;
    position: relative;
}
.scenarioSelect {
    height: 25px;
    width: 200px;
}
.createScenarioButton {
    height: 25px;
    text-wrap: none;
    width: 350px;
}
