.objectListName {
    display: inline;
    cursor: pointer;
    font-weight: bold;
}
.objectListName:hover {
    background-color: rgba(0, 0, 0, 0.05);
    text-decoration: underline;
}
.objectListName:not(:last-child)::after {
    content: ", ";
}
