.icon {
    color: #00D1FF;
}
.icon:hover {
    ccolor: #00D1FF;
}
.iconButton:hover {
    ccolor: #FFFFFF;
    background-color: rgba(0, 209, 255, 0.2)
}
.redIcon {
    color: red;
}
.redIconButton:hover {
    background-color: rgba(255, 0, 0, 0.2)
}
:global .react-tabs {
    height: 100%;
    width: 100%;
    position: relative;
}
.main {
    height: 95%;
    width: 100%;
    position: relative;
}
.applicationsDropDiv {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: scroll;

}
.applicationsDiv {
    height: calc(100% - 40px);
    width: 100%;
}

.splitterComponent {
    height: 100%;
    width: 100%;
}

.bumper {
    width: 100%;
    height: 20px;
}

.lowerControlPane {
    z-index: -1000;
}

.upperControlPane {
    z-index: -1000;
    height: 100%;
}

.filterPane {
    width: 100%;
    height: 40px;
    overflow: hidden;
}
.diagramDiv {
    height:100%;
    width: 100%;
}


.gridStackItem {
    background-color: orange;
}
.gridStackItemContent {
    background-color: orange;
}

.dropZoneActive {
    display: block;
    z-index: 1000;
    width: 100%;
    height:100%;
    position: absolute;
    background-color: #0d419d;
    opacity: 0.5;
}
.dropZoneInactive {
    display: none;
}

.clearButton {
    margin-left: 20px !important;
    padding-left: 20px !important;
}
.diagramWrapperDiv {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
}
.gridShowing .diagramWrapperDiv {
    height: 60%;
}
.gridDiv {
    display: none;
}
.gridShowing .gridDiv {
    display: block;
    height: 40%;

}

.tabContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.gridTabPanel {
    flex: 1 0 auto; /* Grow to absorb space, but don't shrink, and don't base size on content */
    height: calc( 100% - 30px ); /*-20px for the margin-bottom on the Tab that is above it*/
    width: 100%;
    overflow: auto;
}
:global [role="tabpanel"]:not(.react-tabs__tab-panel--selected) {
    display: none;
}
