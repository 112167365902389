.mainDiv {
    background-color: #FFFFFF;
    height: 100%;
    padding-top: 10px;
}
.mainForm {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.headerCell {
    text-align: left;
    vertical-align: top;
}
.leftAlign {
    text-align: left;
}
.workspaceLabel {
    text-align: left;
}
