.mainDiv {

}
.folderTitleDiv {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50px;
}
.folderNameSpan {
    font-weight: bold;
    font-size: 1.2em;
}
.tableDiv {
    width: 100%;
    height: 76vh;
    overflow-y: scroll;
}


