
.main {
    height: calc(100% - 90px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.status {
    height: 60px;
    width: 100%;
    text-align: center;
}
.layoutSelectorDiv {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.layoutSelectorDiv .text {
    padding-right: 5px;
}
