.mainDiv {

}
.mainDivError :global(.quill) {
    border: 1px solid red;
}
.rich-text-labeler {
    min-height: 200px;
    display: inline-block;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.labelSpan {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: inline-block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    left: 10px;
    top: 12px;
    background-color: white;
    opacity: 1;
    z-index: 2000;
}

.label {
    padding-left: 0px;
    padding-right: 0px;
}
.labelError {
    color: red;

}


.dropDownDiv {
    position: fixed;   /* was absolute, but then the dropdown was contained in the react-form. And because the list was sometimes long, it increased the size of the form,
                          which led to the observer being triggered, and in turn led to the error: "ResizeObserver loop completed with undelivered notifications."
                          Now, setting fixed, makes the dropdown be outside the form, and the form does not resize. */
    border: 1px solid black;
    background-color: white;
    z-index: 2000;
}
.dropDownSuggestion:hover {
    color: white;
    background-color: #E45B53;
}
.isSelected {
    color: white;
    background-color: #E45B53;
}
.errors {
    color: red;
    font-weight: 400;
    font-size: 0.75em;
    padding-left: 0;
    margin-left: 0;
}


