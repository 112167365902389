.main {
    height: 100%;
    width: 100%;
}
.titleDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    height: 50px;
}
.redIconButton {

}
.redIcon {
    color: red;
}
.redIconButton {

}
.dragArea {
    position: absolute;
    display: block;
    z-index: 10;
    width: 100%;
    height:100%;
    background-color: #0d419d;
    opacity: 0.5;
    overflow: hidden;
    top: 0;
    left: 0;
}
.stencilDiv {
    position: absolute;
    top:50px;
    height: 60px;
    width: 100%;
    background-color: #F5F5F5;
}
.stencilDiv g.joint-type-bpmn2:hover
{
    stroke: yellow;
    fill: mediumpurple;
}
.paperWrapper {
    top: 110px;
    left: 0;
    position: absolute;
    height: calc( 100% - 100px);
    width: 100%;
    overflow: auto;
}
.navigationWrapper {
    height: 0;
    width: 150px;
    background: none;
    margin-left: calc(100% - 150px);
    z-index: 1000;
}

.navigatorDiv {
    border: #0d1117 1px solid;
    position: absolute;
    display: block;
    height: 100px;
    width: 150px;
    background-color: white;
    z-index: 9997; /* the support form popup has a z-index of 9999 ad the support button on 9998 */
    overflow: hidden;
    bottom: 20px;
    right: 20px;
}

.navigatorDiv :global .current-view {
    border: #FC5185 1px solid;
    color: white;

}

.paperDiv {
    top: 10px;
    height: 100%;
    width: 100%;
}

:global .joint-stencil-item {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
}


:global .joint-stencil {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

:global .joint-cell {
    margin: 5px; /* Adds spacing between items */
    padding-left: 15px; /* Adds padding around the individual stencil item */
    padding-right: 15px; /* Adds padding around the individual stencil item */
}

:global .joint-context-toolbar.joint-theme-bpmn.joint-vertical {
    background-color: #ece7e4;
    padding: 10px;
    border-radius: 5px;
    border: #bbbbbb thin solid;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; /* https://getcssscan.com/css-box-shadow-examples */
}

:global .joint-context-toolbar.joint-theme-bpmn.joint-vertical .tool {
    background-color: #ece7e4;
    border: none;
}

:global .joint-context-toolbar.joint-theme-bpmn.joint-vertical .tool:hover {
    color: white;
    font-weight: bold;
    background-color: rgb(191, 98, 192);
    border-radius: 3px;
}
