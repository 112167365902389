.datePickerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin: 0.5rem 0;
}

.datePickerWrapper button, [data-testid='ArrowRightIcon'], [data-testid='ArrowLeftIcon'] {
    color: rgba(0, 0, 0, 0.87) !important;
}
.fullscreenButton {
    position: absolute;
    top: 5px;
    right: 5px;
}
.actionsDiv {
    display: block;
    right: 50px;
    top: 5px;
    position: absolute;
}
