.main {

}

.propertyRow {
    text-align: left;
    padding: 5px;
}

.label {
    color: black;
    display: block;
}

.value {
    font-weight: bold;
}

.deleteButtonSpan:hover {
    background-color: rgba(255, 0, 0, 0.05);

}

.copyButtonSpan:hover {
    background-color: rgba(50, 50, 50, 0.05);
}

.deleteButtonSpan, .shareButtonSpan {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    bborder: rgba(255, 0, 0, 0.05) solid 1px;
    height: 40px;
    width: 40px;
    position:relative;
    vertical-align: middle;
    text-align: center;
    margin-left: 10px;
    border-radius: 20px;
}
.deleteButtonIcon {
    color:red;
}
.copyButtonIcon {
    color:rgba(50, 50, 50, 1);
    height: 20px;
    width: 20px;
}
.copyButtonIcon:hover {
    color:#00D1FF;
    background-color: rgba(00, 209, 255, 0.1);
}
.copyButtonIcon:active {
    color:#000000;
    background-color: rgba(00, 209, 255, 0.1);
}
.shareButtonIcon {
    color:black;
}
.shareButtonIcon:hover {
    color:#00D1FF;
    background-color: rgba(00, 209, 255, 0.1);
}
.shareOkButtonIcon {
    color:green;
}

.idKey, .idValue {
    color: #999;
}
.typeKey {
}
.typeValue {

    font-weight: bold;
}
.nameKey {
}
.nameValue {
    color:#007A8C;
    font-weight: bolder;
}
.tooltipNameDiv {
    font-weight: bold;
}
.tooltipDescriptionDiv {
    font-style: italic;
    font-size:  smaller;
}
.tooltipContributingFactorsDiv {
    white-space: pre
}
