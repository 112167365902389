.mainDiv {
    width:100%;
    height:100%;
    overflow: auto;
}
.headerDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: flex-end;
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 50;
}
.headerDivTitle {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: xx-large;
}
.actionDiv {
    position: sticky;
    top: 4px;
    left: 0;
    z-index: 51;
}
.maturityTD {
    hheight: 320px;
    wwidth: 300px;
}
.cellInfo {
    mmargin-top: 10px;
    border: none;
    width: 320px;
    padding: 10px;
}
.dimensionName {
    display: block;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
    border-bottom: black dotted 1px;
}
.selectDiv {
    display: block;
    hheight: 300px;
    border: 1px solid black;
    border-radius: 4px;
    width: 320px;
}



.selectDivAsIs {
    display: block;
    margin-top: 10px;
    height: 120px;
    bborder-bottom: 1px solid black;
    wwidth: 325px;

}

.selectDivToBe {
    display:block;
    margin-top: 10px;
    height: 120px;
    wwidth: 325px;
}

.arrowDown {
    position: relative;
    top: -17px;
    left: 50%;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.select_Level {
    text-overflow: initial;
    display: block;
    font-weight: bold !important;
}

.level_initial {
    color: #FB0008;
}

.level_improving {
    color: #B20004;
}

.level_managed {
    color: #FDA4A8;
}

.level_mature {
    color: #DCEDD0;
}
.level_leading {
    color: #447129;
}
.table {
    wwidth: 100%;
    border-collapse: collapse;
    border: none
}



.table tr, .table td, .table th {
    border: none;
}


.select_item {
    text-wrap: balance;
    padding-left: 10px;
    padding-right: 5px;
}

.header {
    position: sticky;
    top: 49px;
    lleft: 0;
    font-weight: bold;
    font-size: 1.2em;
    padding: 10px;
    color: #fff;
    z-index: 50;
}

.select {
    height: 120px;
    width: 100%;
    whiteSpace: 'normal';
    word-wrap: normal;
    padding-left: 2px;
}

.selectLabel {
    font-weight: bold;
    font-size: 1.2em;
    padding: 10px;
    color: #fff;
}

.inputLabel:not(:global .MuiFormLabel-filled):not(:global .MuiInputLabel-shrink) {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%) !important;
    font-weight: bold !important;
    width: 100%;
    text-align: center;
    padding-left:10px;
}

.selectDivSeparator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10px;
    width: 100%;
    margin-top: 10px;
    left: calc( 25% - 3px );
}

.selectDivSeparatorLineLeft {

    border-top: black 1px solid;
    width: 40%;
    display: inline-block;
}

.selectDivSeparatorLineArrow {
    position: relative;
    top: 0px;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.selectDivSeparatorLineRight {
    border-top: black 1px solid;
    width: 40%;
    display: inline-block;
}

.menuItem {
    width: 100%;
    max-width: 320px;
    white-space: normal;
    display: block;
    border-bottom: 1px solid #e0e0e0;
}
.menuItem:nth-last-child(1) {
    /*kudos to https://stackoverflow.com/a/66185135/425677*/
    border-bottom: none;
}
.selectLevel {
    font-weight: bold;
}
.selectMaturityValue {
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 65px;
    white-space: pre-wrap;
    font-size: 0.8rem;
    line-weight: 1.3rem;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Where N is the number of lines you want to show. Adjust this as needed.*/
    -webkit-box-orient: vertical;
}
