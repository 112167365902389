.mainDiv {
    display: block;
    margin-top: 20px;

    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.mainDiv.open {
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #CCF7FF;
    text-align: center;
}

.contentDiv {
    overflow: hidden;
    margin: 10px;
}

.contentShow {
    max-height: 500px;
    transition: max-height;

}
.contentHide {
    max-height: 0;
    transition: max-height;

}
