.mainDiv {
    background-color: #FFFFFF;
    height: 100%;
    overflow: auto;
}
.statusDiv {

}
.statusMessage {

}
.headerCell {
    text-align: left;
    vertical-align: top;
    padding-top: 15px;
    padding-bottom: 15px;
}
.valueCell {
    padding-top: 15px;
    padding-bottom: 15px;
}
.leftAlign {
    text-align: left;
}
.rightAlign {
    text-align: right;
}
.workspaceLabel {
    text-align: left;
}
.workspaceTable {
    padding-top: 20px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.workspaceListTable {
    width: 100%;
}
.workspaceListTable * {
    border: none;
    border-collapse: collapse;
}
.workspaceListTable td {
    padding-left: 5px;
    padding-right: 5px;
}

.evenRows {
    background-color: #EFEFEF;
}
.oddRows {

}
.closeIconButton {
    color: red;
}
.leaveIconButton {
    color: red;
}
