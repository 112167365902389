.main {
    width: 95%;
    height: 100%;
    background-color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
}
.loaderSpan {

}


