.main {
    width: 100%;
    height: 60px;
    background-color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
/* kudos to: https://css-tricks.com/animating-border/ */
.linkButton, .selectedLinkButton {
    display: inline-flex;
    height: 30px;
    min-width: 150px;
    text-align: center;
    background-color: #00D1FF;
    margin: 3px;
    padding: 5px;
    outline: solid 5px #5CE2FF;
    transition: outline 0.6s linear;
    margin: 0.5em; /* Increased margin since the outline expands outside the element */
    font-weight: bolder;
}

.selectedLinkButton {
    background-color: #5CE2FF;
    outline: solid 5px #FC5185;
}

.linkButton a, .selectedLinkButton a {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    color: white;
    text-decoration: none;
    height: 100%;
    width: 100%;
}

.linkButton:hover {
    outline-width: 10px;
}

.content {
    padding-top: 50px;
    width: 100%;

}

