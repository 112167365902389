.card {
    display: inline-block;
    user-select: none;
    background-color: lightgrey;
}
.actorCard {
    background-color: #a41cda;
    color: white;
    font-weight: bold;
}
.applicationCard {
    background-color: skyblue;
}
.businessProcessCard {
    background-color: #ea796a;
}
.capabilityCard[data-level='even'] {
    background-color: orange;
}
.capabilityCard[data-level='odd'] {
    background-color: #FFE97A;
}
.dataObjectCard, .dataExchangeCard, .dataFlowCard {
    background-color: skyblue;
}
.designDecisionCard {
    background-color: #1AA89C;
}
.functionalityCard {
    background-color: #CCF7FF;
}
.labelCard {
    cursor: auto !important;
    background-color: white;
    opacity: 0.5;
}
.middlewareCard {
    background-color: #E2F0D9;
}
.principleCard {
    background-color: #FFFF00;
}
.technologyCard {
    background-color: #E2F0D9;
}
.isSelected {
    border: #FC5185 solid 3px;
    margin: -3px;
}
.card:hover {
    cursor: pointer;
    filter:drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    transition-duration: 0.1s;
}
.card:active {
    top: 1px;
}
.card:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top:0;
    opacity: 1;
    transition: 0s;
}
.nodeName {
    padding: 5px;
}
