.mainDiv {
    text-align: left;
    background-color: white;
    margin-top: 50px;
    padding-top: 50px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.introDiv {
    background-color: #FFFFFF;
    text-align: left;
    padding-bottom: 20px;
}

.paper {
    border: none;
    box-shadow: none !important;
}

h1 {
    text-align: center;
}
h2, p, .paragraph {

    margin-left: 20px;
    margin-right: 20px;
}

