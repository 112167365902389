.bulletedTextField {
    width: 100%;
    line-height: 1.5em;
    font-family: inherit;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 4px;

}

.bulletedTextField::before {
    content: var(--bullet-char) " ";
    position: absolute;
    left: 10px;
    color: black;
}

.bulletedTextField {
    --text-indent: -20px;
    white-space: pre-wrap; /* Preserve line breaks */
}
