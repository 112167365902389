.main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 20px);
    padding: 10px;
}

.noResultsMessage {
    padding-top: 20px;
    width: 100%;
    text-align: center;

}



.searchText {
    font-weight: bolder;
}

.searchResult {
    user-select: none;
}
.searchResult:hover {
    background-color: #00D1FF;
}

.resultText {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: none;
    width: 90%;
    display: inline-block;
}

.clearSearchDiv {
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
}

.clearSearchButton {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
}
