.main {
    padding-top: 50px;
    width: 95%;
    height: 100%;
    background-color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
}

.paper {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-decoration: none;
    padding: 10px;

}
.link, .linkDescription {
    display: grid;
    width: 100%;
    text-decoration: none;
    padding-bottom: 5px;
    color: black;
    text-align: center;
}

.paper:hover {
    outline: solid 5px #00D1FF;
    transition: outline 0.6s linear;
}

.paper:hover > .link, .paper:hover > .linkDescription {
    color: #545353;
    transition: color 0.6s linear;
}

.link {
    height: 40%;
    font-weight: bold;
    align-content: end;
}
.linkDescription {
    height:60%;
    align-content: start;
}

.content {
    padding-top: 50px;
    width: 100%;
}
.proIndicator {
    color: #00D1FF;
    height: 30px;
    width: 100%;
    text-align: center;
}
