.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    padding: 0 20px;
    box-sizing: border-box;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #333333;
    line-height: 1.5;
    font-weight: 400;
    letter-spacing: 0.00938em;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.wizardWrapper {
    width: 100%;
    max-width: 700px;
}

.stepActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
}
