.main {
    overflow: scroll;
    height: 100%;
    width: 100%;
}


.nodeName {
    padding: 5px;
}
.filterPane {
    width: 100%;
    height: 40px;
}

.newApplication {
    background-color: #155724;
    color: #c3e6cb;
}

.updatedApplication {
    background-color: orange;
    color: white;
}

.deletedApplication {
    background-color: #721c24;
    color: #f5c6cb !important;;
}

.labelNewApplication {
    color: #155724;
    font-weight: bolder;
}
.labelUpdatedApplication {
    color: orange;
    font-weight: bolder;
}
.labelDeletedApplication {
    color: #721c24;
    font-weight: bolder;
}
