.main {
    color: black;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}
:global .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
    color: black;
}
:global .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator {
    color: red;
}
