.main {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.dragArea {
    position: absolute;
    display: block;
    z-index: 10;
    width: 100%;
    height:100%;
    background-color: #0d419d;
    opacity: 0.5;
    overflow: hidden;
    top: 0;
    left: 0;
}

.paperWrapper {
    top: 60px;
    left: 0;
    position: absolute;
    height: calc( 100% - 60px);
    width: 100%;
    overflow: auto;
}

.paperDiv {
    height: 2800px;
    width: 1600px;
}
.titleDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    height: 50px;
}
.redIcon {
    color: red;
}
.redIconButton {

}

.dialogInnerContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}
