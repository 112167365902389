.mainDiv {
    position: relative;
    width: 100%;
    height: 100%;
}

.selectAFolderOrNodeDiv {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
}
.selectAFolderOrNodeDiv span {
    margin: 0 auto;
    user-select: none;
    font-weight: bold;
}
