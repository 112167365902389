.main {
    overflow: scroll;
    height: 100%;
    width: 100%;
}

.filterPane {
    width: 100%;
    height: 40px;
}
