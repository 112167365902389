.mainDiv {
    background-color: #FFFFFF;
    height: 100%;
}
.statusDiv {

}
.statusMessage {

}
.headerCell {
    text-align: left;
    vertical-align: top;
    padding-top: 15px;
    padding-bottom: 15px;
}
.valueCell {
    padding-top: 15px;
    padding-bottom: 15px;
}
.leftAlign {
    text-align: left;
}
.rightAlign {
    text-align: right;
}
.workspaceLabel {
    text-align: left;
}
.workspaceTable {
    padding-top: 20px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.workspaceListTable {
    width: 100%;
}
.workspaceListTable * {
    border: none;
    border-collapse: collapse;
}
.workspaceListTable td {
    padding-left: 5px;
    padding-right: 5px;
}

.evenRows {
    background-color: #EFEFEF;
}
.oddRows {

}
.closeIconButton {
    color: red;
}
.leaveIconButton {
    color: red;
}
.workspaceCountOK, .objectLimitOK {
    font-size: xxx-large;
    color: #00D1FF;
    font-weight: bold;
}
.workspaceCountNOK, .objectLimitNOK {
    font-size: xxx-large;
    color: red;
    font-weight: bold;
}
.workspaceLimit, .objectLimit {
    font-size: larger;
    color: black;
    font-weight: bolder;
}
.subObjectCounter {
    font-weight: bolder;
}

.maxedLimitMessage {
    text-align: right;
    width: 100%;
    color: red;
}
