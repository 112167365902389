div.l1tier1 {
    background-color: lightblue;
    color: white;
}
div.l1tier2 {
    background-color: Crimson;
    color: white;
}
div.l1tier3 {
    background-color: lightgray;
    color: white;
}

div.l2tier1 {
    background-color: cornflowerblue;
    color: white;
    font-size: 10px
}
div.l2tier2 {
    background-color: palevioletred;
    color: white;
    font-size: small
}
div.l2tier3 {
    background-color: gray;
    color: white;
    font-size: small
}
