.main {
    display: block;
    position: fixed;
    z-index: 1000;
    background-color: #ece7e4;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    border: #bbbbbb thin solid;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; /* https://getcssscan.com/css-box-shadow-examples */
}

.optionWrapper {
    width: 100%;
}

.title {
    font-style: italic;
}

.option {

}

.option, .title {
    background-color: #ece7e4;
    display: table;
    height: 25px;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;

}

.option > span, .title > span {
    display: table-cell;
    vertical-align: middle;
    padding-left: 7px;
    padding-right: 7px;
}

.option:hover {
    color: white;
    font-weight: bold;
    background-color: rgb(191, 98, 192);
    border-radius: 3px;
}

.separator {
    height: 1px;
    background-color: rgb(212, 206, 203);
    margin-top: 2px;
    margin-bottom: 2px;
}
