.main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.plateaus {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.plateau {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    border: #0d1117 1px solid;
    padding-right: 50px;
}
.solutions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.solution {
    border: #0d1117 1px solid;
    width: 100%;

}
.solutionName:hover {
    cursor: pointer;
}
h4, p, ul {
    margin-block-end: 0;
    margin-block-start: 0;
}
.noDeps {
    color: #999999;
}

.noDependencies {
    color: lightgreen;
}

.noDependants {
    color: #0f5323;
}

.hasDeps {
    color: black;
}

.message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
