.main {
    width: 95%;
    height: 100%;
    background-color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
}
.actorFragment {

}

.report {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
