.stepList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    list-style: none;
}
.stepRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
}
.stepListStep {
    display: inline-grid;
    align-items: center;
    font-size: larger;
    font-weight: bolder;
    ppadding: 15px;
    background-color: #007A8C;
    mmargin: 20px;
    border-radius: 20px;
    color: white;
    width: 40px;
    height: 40px;

}
.stepListContent {
    padding-left: 10px;
    text-align: left;
    width: 95%;
    font-weight: bold;

}
