.main {
    width: 95%;
    height: 100%;
    background-color: #FFFFFF;
    margin-left: auto;
    margin-right: auto;
}
.noDataSpan {
    padding-top: 50px;
    display: block;
    width: 100%;
    text-align: center;
}
.treeMap {
    width: calc( 100% - 10px );
    height: calc( 100% - 70px );
    margin-left: auto;
    margin-right: auto;
}

.wrapper {
    width: 100%;
    height: 100%;
    //border: 1px solid blue;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

