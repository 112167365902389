.mainDiv {
    padding-left: 50px;
    padding-right: 50px;
}


.hiddenLabel {
    display: none;
}

.loadingSpan {
    font-style: italic;
    color: white;
}

.errorLoadingSpan {
    font-style: normal;
    font-weight: bold;
    color: #FF6F61;
}
.createSpan {
    text-decoration: underline;
}
.createSpan:hover {
    cursor: pointer;
}
