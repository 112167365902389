.mainDiv {
    background-color: #FFFFFF;
    height: 100%;
}
.tableDiv {
    margin-left: auto;
    margin-right: auto;
}
.table {
    margin-left: auto;
    margin-right: auto;
}
.closeIconButton {
    color: red;
}
.infoBoxWrapper {
    padding: 20px;
}
