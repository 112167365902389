.counterDiv {
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    width: 200px;
    height: 100px;
    margin: 5px;
    padding: 5px;
    background-color: azure;
}
.counterLabel {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 35px;
    width: 100%;
    background-color: #007A8C;
    font-size: small;
    font-weight: bolder;
    color:azure;
}

.counterValueDiv {
    flex: 1 1 auto;
    width:100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    color: black;
    background-color: #B2F5FF;
}

.counterValue {
    font-size: xx-large;
    font-weight: bolder;
    text-align: center;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    color: #007A8C;
}

.counterImage {
    width: 40px;
    height: 40px;
}

.iconSpan {
    display: inline-flex;
    align-content: center;
    justify-content: center;
    width: 40px;
    flex-wrap: wrap;
}
.loading {
    background-color: #AAAAAA;
}
