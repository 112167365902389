.modelObject {
    font-weight: bolder;
}
.modelObject.capability {
    color: orange;
}
.modelObject.application {
    color: skyblue;
}
.modelObject.dataObject {
    color: #9fc9f5;
}
.modelObject.dataExchange {
    color: #9fc9f5;
}
.modelObject.dataFlow {
    color: #9fc9f5;
}
.modelObject.folder {
    color: black;
}
.modelObject.actor {
    color: #a41cda;
}
.modelObject.functionality {
    color: #afec6e;
}
.modelObject.businessProcess {
    color: #ea796a;
}
