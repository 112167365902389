.mainDiv {
    background-color: white;
    width: 100%;
    height: 100%;

}

.formDiv {
    width: 60%;
    max-width: 500px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
