.main {
    width: 100%;
    height: 40px;
    border-bottom: #d9d9d9 dashed 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main .icon {
    color: #00D1FF;
}
.main .iconButton:hover {
    background-color: rgba(0, 209, 255, 0.2);
}
.main .redIcon {
    color: red;
}
.main .redIconButton:hover {
    background-color: rgba(255, 0, 0, 0.2)
}
:global .react-tabs {
    height: 100%;
    width: 100%;
    position: relative;
}
