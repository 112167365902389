.trialEndDate {
  color: #00D1FF;
}
.priceTableWrapperDiv {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 25px;
  overflow: auto;
  height: calc(100% - 85px);
}

.priceTableRight {
  border-collapse: collapse;
}

.priceTableLeft tr:nth-child(1) td:nth-child(2) {
  background-color: #FFFFFF;
}

.priceTableRight tr:nth-child(2) td:nth-child(2) {
  border-top: 1px solid #000000; /* Adjust the border style as needed */
  border-left: 1px solid #000000; /* Adjust the border style as needed */
  border-right: 1px solid #000000; /* Adjust the border style as needed */
  border-collapse: collapse;
}

.priceTableRight tr:nth-child(3) td:nth-child(2) {
  border-left: 1px solid #000000; /* Adjust the border style as needed */
  border-right: 1px solid #000000; /* Adjust the border style as needed */
}

.priceTableRight tr:nth-child(4) td:nth-child(2) {
  border-left: 1px solid #000000; /* Adjust the border style as needed */
  border-right: 1px solid #000000; /* Adjust the border style as needed */
  border-bottom: 1px solid #000000; /* Adjust the border style as needed */
}

.priceTableLeft tr:nth-child(5) td:nth-child(2) {
  background-color: #FFFFFF;
}


.priceTableLeft {
  border-collapse: collapse;
  background-color: #FAFAFA;
}

.priceTableLeft tr:nth-child(1) td:nth-child(1) {
  background-color: #FFFFFF;
}

.priceTableLeft tr:nth-child(2) td:nth-child(1) {
  border-top: 1px solid #000000; /* Adjust the border style as needed */
  border-left: 1px solid #000000; /* Adjust the border style as needed */
  border-right: 1px solid #000000; /* Adjust the border style as needed */
}

.priceTableLeft tr:nth-child(3) td:nth-child(1) {
  border-left: 1px solid #000000; /* Adjust the border style as needed */
  border-right: 1px solid #000000; /* Adjust the border style as needed */
}

.priceTableLeft tr:nth-child(4) td:nth-child(1) {
  border-left: 1px solid #000000; /* Adjust the border style as needed */
  border-right: 1px solid #000000; /* Adjust the border style as needed */
  border-bottom: 1px solid #000000; /* Adjust the border style as needed */
}

.priceTableLeft tr:nth-child(5) td:nth-child(1) {
  background-color: #FFFFFF;
}

.imageCell {

}
.imageSpanWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom:20px;
}
.imageSpan {
  display: inline-block;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.proImageSpan {
  width: 100px;
  height: 100px;
}
.standardImageSpan {
  width: 150px;
  height: 100px;
}
.titleRow {
  height: 50px;
  text-align: center;
  font-size: larger;
  font-weight: 700;
}
.priceAndDurationDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.priceSpanWrapper {
  font-weight: 700;
  font-size: 36px;
}
.priceDuration {
  font-size: 13px;
}

.actionButton {

}
.featuresRow {
  vertical-align: top;
}
.buttonRow {
  height: 70px;
}
.buttonCell {
  align-content: center;
    align-items: center;
  text-align:   center;
}
.currentPlanSpan {
  font-size: 24px;
  font-weight: 700;
  color: #00D1FF;
}
.textRow {
    height: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}

