.mainDiv {
    display: flex;
    flex-direction: row;
    align-items: start;
}
.buttonSpan {
    width: 70px;
}
.button {
    font-size: 10px;

}

.progressSpan {
    display: block;
    padding-top: 13px;
    width: 80px;
    height: 20px;
}
