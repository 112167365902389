
.labelDiv {
    display: flex;
    align-items: center; /* Vertically centers the children */
}
.labelIcon {
    margin-right: 8px; /* Optional: Add space between icon and text */
}
.labelName {
    display: flex;
    align-items: center; /* Ensures the text itself is vertically centered */
}
