.main {
    height: calc( 100% - 20px );
    width: 100%;
    bbackground-color: orange;
}

.navigationWrapper {
    height: 0;
    width: 150px;
    background: none;
    margin-left: calc(100% - 150px);
    z-index: 1000;
}

.navigatorDiv {
    border: #0d1117 1px solid;
    position: absolute;
    display: block;

    background-color: white;
    z-index: 9998; /* the support form popup has a z-index of 9999 ad the support button on 9998 */
    overflow: hidden;
    bottom: 20px;
    right: 20px;
}

.navigatorDiv :global .current-view {
    border: #FC5185 1px solid;
}

.paperDiv {
    height: 100%;
    width: 100%;
}

.gridDiv {
    pposition: relative;
    ddisplay: block;
    background-color: white;
    height: 100%;
    width: 100%;
}

:global .grid-stack-item-content {
    background-color: #18bc9c;
}


.capability {
    height: 100%;
    width: 100%;
}

.capabilityEven {
    background-color: orange;
}

.capabilityOdd {
    background-color: yellow;
}

.application {
    background-color: skyblue;
    height: 100%;
    width: 100%;
}

.gridStackItemContent {
    height: 100%;
    width: 100%;
    background-color: skyblue;
}

.gridContentSpan {
    top: calc(50% - 9px);
    position: relative;
}

.gridItemActions {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.gridActionIconSpan {
    position: absolute;
    width: 100%;
    text-align: right;
    align-items: end;
    justify-content: right;
    justify-items: right;
    font-size: 18px;
    top: 0;
    opacity: 1;
}
