.main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 15px;
}
.wrapper {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: #CCF7FF;
}
