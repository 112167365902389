.mainDiv {

}

.dialogTitle {

}

.actionsDiv {
    display: block;
    right: 0;
    bottom: 5px;
    position: static;
}

.form {

}

.submit {

}
.fullscreenButton {
    position: absolute;
    top: 5px;
    right: 5px;
}
