.mapDiv {
}
.downloadButton {

}
.actionBar {
  width: 100%;
}
.internalActionBar {
  display: inline-flex;
}
.ca {
  min-height: 100%;
  min-width: 100%;
  color: black;
  background-color: white;
  display: inline-block;
}
.caPanel {
  background-color: #f9a011;
  height: 250px;
  width: 300px;
  overflow-y: clip;
}
.caTitle {
  font-weight: bolder;
}
.subCapaListDiv {
  display: block;
  padding-top: 10px;
}
.subCapaDiv {
  border-color: #f9bb11;
  background-color: #f9bb11;
  border-radius: 2px;
  display: inline-block;
  padding: 5px;
  margin: 2px;
  font-size: xx-small;
  overflow: hidden;
  width: 55px;
  height: 30px;
  text-overflow: ellipsis;

}
.capaNameParagraph {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  text-overflow: ellipsis;
}

.highlightCapaL1, .highlightCapaL2 {
  border-width: 4px;
  margin: -4px;
  border-style: dotted;
  border-color: #80ee0c;
  color: white;
}
.nontiered {
  background-color: #f9bb11;
}
div.cardHeader {
  padding: 4px;
}
.importButtonDiv {
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 20px;
}
