.objectList {
    padding-inline-start: 15px;
}
.objectListItem {
    font-weight: normal;
}
.objectListNameDiv {
    font-weight: bold;
    display: inline;
}
.objectListDescriptionDiv {
    font-style: italic;
    font-size:  smaller;
    display: inline;
}
