.mainDiv {
    background-color: #FFFFFF;
    height: 100%;
    width: 100%;
}
iframe {
    /*background-color: #00D1FF;*/
    width: 100%;
    height: 100%;
    border: none;
}
