@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

span.downloading {
    display: inline-block; /* Required for transform */
    animation: rotate 2s linear infinite; /* Rotate continuously over 2 seconds */
}

.main {
    display: grid;
    align-items: center;
}
.main .icon {
    color: #00D1FF;
}

.main .icon .downloading {
    color: lightgrey;
}


.main .iconButton:hover {
    color: #FFFFFF;
    background-color: rgba(0, 209, 255, 0.2);
}
