.trialEndDate {
    color: #00D1FF;
}
.mainDiv {
    background-color: #FFFFFF;
    height: 100%;
}
.accountTable {
    padding-top: 20px;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.headerCell {
    text-align: left;
    vertical-align: top;
    padding-top: 15px;
    padding-bottom: 15px;
}
